import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

// SwissClassicalWorld
import Home from "./views/Home";
import Events from "./views/Step05_Events";
import Event from "./views/Step10_Event";
import Address from "./views/Step20_Address";
import Confirmation from "./views/Step30_Confirmation";

// Fallback page
import PageNotFound from "./views/components/PageNotFound";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigate to="/de/home" replace />} />
        <Route path="*" element={<PageNotFound />} />

        <Route path="/:language/home" element={<Home />} />
        <Route path="/:language/events" element={<Events />} />
        <Route path="/:language/event/:eventId" element={<Event />} />
        <Route path="/:language/address" element={<Address />} />
        <Route path="/:language/confirmation" element={<Confirmation />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
