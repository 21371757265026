import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import parse from "html-react-parser";

import "../css/style.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Loading from "./components/Loading";

const Step10_Event = () => {
  const [token] = useState(sessionStorage.getItem("token"));
  const [orderId] = useState(sessionStorage.getItem("orderId"));
  const [event, setEvent] = useState({});
  const [eventInfo, setEventInfo] = useState({});
  const [eventLocation, setEventLocation] = useState({});
  const [ticketTypeId, setTicketTypeId] = useState();
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState("");

  // react hook for navigation
  let navigate = useNavigate();

  // base url
  let baseUrl = process.env.REACT_APP_BASEURL_API;

  // fetching resources
  const [resources, setResources] = useState({});

  const { language } = useParams();
  const { eventId } = useParams();

  let languageId = 0;

  if (language === "de" || language === "DE") {
    languageId = 1;
  } else if (language === "en" || language === "EN") {
    languageId = 3;
  } else {
    languageId = 1;
  }

  useEffect(() => {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;

    requestFormSettings();
  }, []);

  useEffect(() => {
    requestResources();
  }, [language]);

  const requestFormSettings = async () => {
    await axios.get(`form/formsettings`).then((res) => {
      setLoading(true);
      requestEvent(eventId);
    });
  };

  const requestResources = async () => {
    await axios
      .get(`form/resources/${language}`)
      .then((res) => {
        setResources(res.data.translation);
      })
      .catch((error) => console.error(error.response.data));
  };

  const requestEvent = async (id) => {
    await axios
      .get(`${baseUrl}/Event/${id}`)
      .then((res) => {
        setEvent(res.data);
        requestTicketType(id);
        requestEventInfo(id);
        requestEventLocation(res.data.locationId);
      })
      .catch((error) => console.error(error.response.data));
  };

  const requestEventInfo = async (id) => {
    await axios
      .get(`${baseUrl}/Event/${id}/Infos`)
      .then((res) => {
        setEventInfo(
          res.data.eventInfos.find((infos) => infos.languageId === languageId)
        );
      })
      .catch((error) => console.error(error.response.data));
  };

  const requestEventLocation = async (locationId) => {
    await axios
      .get(`${baseUrl}/Location/${locationId}/Infos`)
      .then((res) => {
        setEventLocation(res.data.locationInfos[0]);
      })
      .catch((error) => console.error(error.response.data));
  };

  const requestTicketType = async (id) => {
    await axios
      .get(`${baseUrl}/Event/${id}/TicketTypes`)
      .then((res) => {
        let ticketTypes = [];
        ticketTypes = res.data.ticketTypes;
        setTicketTypeId(ticketTypes ? ticketTypes[0].id : 0);
      })
      .catch((error) => console.error(error.response.data));

    setTimeout(() => setLoading(false), 500);
  };

  const addTicketToBasket = async () => {
    let addTicketTypes = [{ ticketTypeId: ticketTypeId, quantity: 1 }];

    await axios
      .post(`${baseUrl}/ShopBasket/Order/${orderId}/Tickets`, {
        ticketsToAdd: addTicketTypes,
      })
      .then(() => {
        setErrors("");
        navigate(`/${language}/address`);
      })
      .catch((error) => {
        setErrors(error.response.data);
      });
  };

  const eventInformation = () => {
    let start = new Date(event?.start);
    let end = new Date(event?.end);

    let day = start.toLocaleString(language, { day: `2-digit` });
    let month = start.toLocaleString(language, { month: `long` });
    let year = start.toLocaleString(language, { year: `numeric` });
    let date = start.toLocaleDateString();

    let startTime = start.toLocaleString(language, { timeStyle: `short` });
    let endTime = end.toLocaleString(language, { timeStyle: `short` });

    let startDate = day + ". " + month + " " + year;

    if (languageId == 3) {
      startDate = month + " " + day + ", " + year;
    }

    const { description, street, streetNumber, postalCode, city } = eventLocation;

    return (
      event &&
      event.start && (
        <div className="row">
          <div className="col-md-8 col-12 pe-4">
            <p className="fs-5 fw-bold">{eventInfo?.name}</p>
            <div className="col-12 mb-3">
              <img className="img-fluid" src={eventInfo?.bannerImagePath}></img>
            </div>
          </div>
          <div className="col-md-4 col-12">
            <p className="fs-5 fw-bold mb-0">{startDate}</p>
            {<><p className="fs-6 mb-0">{description && description}</p>
              <p className="fs-6 mb-3">{(street != null ? street : "") + " " + (streetNumber != null ? streetNumber + ", " : "") + postalCode + " " + city}</p></>}
            <p className="fs-6 mb-0">{resources?.Start}: {startTime} {resources?.Time}</p>
            <p className="fs-6 mb-0">{resources?.End}: {endTime} {resources?.Time}</p>
          </div>
          <div className="col-12 mt-3 mb-0">
            {eventInfo &&
              eventInfo.longDescription &&
              parse(eventInfo.longDescription)}
          </div>
          <div className="col-12 mb-3"><p className="text-danger">{errors}</p></div>
          <div className="col-12 text-end">
            <button className="button" onClick={() => addTicketToBasket()}>
              {resources?.ButtonEvent}
            </button>
          </div>
        </div>
      )
    );
  };

  return (
    <div className="page-container pt-0">
      <Header />
      {!loading ? <div className="container wrapper mb-5">{eventInformation()}</div> : <Loading
        alignment="center"
        color="#f6b326"
        bgColor="#fff"
        position="fixed"
        top="50%"
        left="50%"
      />}
      <Footer />
    </div>
  );
};

export default Step10_Event;
