import React from "react";

const Footer = () => {
  return (
    <div className="footer">
      <div className="container wrapper">
        <div className="row">
          <div className="col-6">
            <ul className="footer-list">
              <li className="fw-bold mb-2">Tessy Chilton</li>
              <li>Corporate Communications & Events Manager</li>
              <li>chilton.tessy@ch.sika.com</li>
            </ul>
          </div>
          <div className="col-6">
            <ul className="footer-list">
              <li className="fw-bold mb-2">Sika AG</li>
              <li>Zugerstrasse 50 · 6340 Baar · Switzerland</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
