import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

import "../css/style.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Loading from "./components/Loading";

const Step05_Events = () => {
    const [orderId, setOrderId] = useState();
    const [loading, setLoading] = useState(true);
    const [events, setEvents] = useState([]);
    const [eventStats, setEventStats] = useState([]);
    const [eventsInfo, setEventsInfo] = useState([]);
    const [eventsLocation, setEventsLocation] = useState([]);
    const [eventAvailability, setEventAvailability] = useState([]);
    const [ticketCount, setTicketCount] = useState([]);

    // react hook for navigation
    let navigate = useNavigate();

    // base url
    let baseUrl = process.env.REACT_APP_BASEURL_API;

    // fetching resources
    const [resources, setResources] = useState({});

    // fetching resources
    const { language } = useParams();

    let languageId = 0;

    if (language === "de" || language === "DE") {
        languageId = 1;
    } else if (language === "en" || language === "EN") {
        languageId = 3;
    } else {
        languageId = 1;
    }

    useEffect(() => {
        loadToken();
    }, []);

    useEffect(() => {
        requestResources();
    }, [language]);

    const loadToken = async () => {
        await axios.get("form/token").then((res) => {
            axios.defaults.headers.common["Authorization"] = "Bearer " + res.data;

            sessionStorage.setItem("token", res.data);

            requestFormSettings();
        });
    };

    const requestFormSettings = async () => {
        await axios.get(`form/formsettings`).then((res) => {
            startOrder(res.data.posId, res.data.eventGroupBSAId);
        });
    };

    const requestResources = async () => {
        await axios
            .get(`form/resources/${language}`)
            .then((res) => {
                setResources(res.data.translation);
            })
            .catch((error) => console.error(error.response.data));
    };

    const startOrder = async (posId, eventGroupId) => {
        let order = {
            affiliateShopId: null,
            currency: "CHF",
            tenantId: 1,
            languageId: languageId,
            pointOfSaleId: posId,
            abbreviation: "",
            paymentType: 1,
        };

        await axios
            .post(`${baseUrl}/ShopBasket/Order`, order)
            .then((res) => {
                sessionStorage.setItem("orderId", res.data.id);

                setOrderId(res.data.id);
                setLoading(true);
                requestEventsInEventGroup(eventGroupId, res.data.id);
            })
            .catch((error) => console.error(error.response.data));
    };

    const requestEventsInEventGroup = async (eventGroupId, orderId) => {
        await axios
            .get(
                `${baseUrl}/EventGroup/Events?eventGroupId=${eventGroupId}&languageCode=de`
            )
            .then((res) => {
                let events = [];

                events = res.data.events;

                setEvents(events);


                events.map((event) => requestEvent(event.id));
                events.map((event) => requestEventsInfo(event.id));
                events.map((event) => requestTicketCount(event.id));
                events.map((event) => requestTicketType(event.id, orderId));
            })
            .catch((error) => console.error(error.response.data));
    };

    const requestEvent = async (id) => {
        await axios
            .get(`${baseUrl}/Event/${id}`)
            .then((res) => {
                setEventStats((current) => [
                    ...current,
                    res.data
                ]);

                requestEventsLocationInfo(id, res.data.locationId);
            })
            .catch((error) => console.error(error.response.data));
    };

    const requestTicketCount = (id) => {
        axios
            .get(`${baseUrl}/Event/${id}/TicketsSold`)
            .then((res) => {
                setTicketCount((current) => [
                    ...current,
                    {
                        eventId: id,
                        ticketsSold: res.data
                    },
                ]);
            })
            .catch((error) => console.error(error.response.data));
    }

    const requestEventsInfo = async (id) => {
        await axios
            .get(`${baseUrl}/Event/${id}/Infos`)
            .then((res) => {
                setEventsInfo((current) => [
                    ...current,
                    res.data.eventInfos.find((tti) => tti.languageId === languageId),
                ]);
            })
            .catch((error) => console.error(error.response.data));

        setTimeout(() => setLoading(false), 500);
    };

    const requestEventsLocationInfo = async (eventId, locationId) => {
        await axios
            .get(`${baseUrl}/Location/${locationId}/Infos`)
            .then((res) => {
                let locationObj = {
                    eventId: eventId,
                    street: res.data.locationInfos[0]?.street,
                    city: res.data.locationInfos[0]?.city
                }

                setEventsLocation((current) => [
                    ...current,
                    locationObj,
                ]);
            })
            .catch((error) => console.error(error.response.data));
    };

    const requestTicketType = (eventId, orderId) => {
        axios
            .get(`${baseUrl}/Event/${eventId}/TicketTypes`)
            .then((res) => {
                let ticketTypesArray = [];

                ticketTypesArray = res.data.ticketTypes;

                ticketTypesArray.map((tt) =>
                    requestTicketTypeNumberOfAvailableTickets(tt.id, eventId, orderId)
                );
            })
            .catch((error) => console.error(error.response.data));
    };

    const requestTicketTypeNumberOfAvailableTickets = (ticketTypeId, eventId, orderId) => {
        axios
            .get(`${baseUrl}/TicketType/${ticketTypeId}/Availability?orderId=${orderId}`)
            .then((res) => {
                setEventAvailability((current) => [
                    ...current,
                    {
                        eventId: eventId,
                        status: res.data.status,
                        availableTickets: res.data.availableTickets,
                    },
                ]);
            })
            .catch((error) => console.error(error.response.data));
    };

    const submit = (eventId) => {
        navigate(`/${language}/event/${eventId}`);
    };

    const mapEvents = () => {
        let eventArray = [];

        eventArray = events;

        eventArray.map((event, index) => {
            eventArray[index].availableTickets = eventAvailability.find((ea) => ea.eventId == eventArray[index].id) && eventAvailability.find((ea) => ea.eventId == eventArray[index].id).availableTickets;
            eventArray[index].status = eventAvailability.find((ea) => ea.eventId == eventArray[index].id) && eventAvailability.find((ea) => ea.eventId == eventArray[index].id).status;
            eventArray[index].contigent = (eventStats.find((es) => es.id == eventArray[index].id) && eventStats.find((es) => es.id == eventArray[index].id).maxTickets) - (ticketCount.find((tc) => tc.eventId == eventArray[index].id) && ticketCount.find((tc) => tc.eventId == eventArray[index].id).ticketsSold)
        })

        eventArray.sort((a, b) => new Date(a.start) - new Date(b.start));

        return (
            eventArray.length > 0 &&
            eventArray.map((event, index) => (
                <div className="row mb-5" key={index}>
                    <div className="col-md-6 col-12 pb-3">
                        <img
                            className="img-fluid"
                            src={
                                eventsInfo.find((ei) => ei?.eventId == event?.id) &&
                                eventsInfo.find((ei) => ei?.eventId == event?.id)
                                    .bannerImagePath
                            }
                        ></img>
                    </div>
                    <div className="col-md-6 col-12">
                        <p className="fs-6 fw-bold mb-0">{eventsInfo.find((ei) => ei?.eventId == event?.id) &&
                            eventsInfo.find((ei) => ei?.eventId == event?.id)
                                .name}</p>
                        <p className="fs-6 mb-2">{eventsLocation.find((el) => el.eventId == event.id) && ((eventsLocation.find((el) => el.eventId == event.id).street ? eventsLocation.find((el) => el.eventId == event.id).street : "") + " " + eventsLocation.find((el) => el.eventId == event.id).city)}</p>
                        <p className="mb-0">
                            {resources?.Start}: {eventStats.find((es) => es?.id == event?.id) &&
                                new Date(eventStats.find((es) => es?.id == event?.id).start).toLocaleString(language, { timeStyle: `short` })} {resources?.Time}
                        </p>
                        <p>
                            {resources?.End}: {eventStats.find((es) => es?.id == event?.id) &&
                                new Date(eventStats.find((es) => es?.id == event?.id).end).toLocaleString(language, { timeStyle: `short` })} {resources?.Time}
                        </p>
                    </div>

                    <div className="col-12 mb-2">
                        <div className="row">
                            <div className="col-md-6 col-12">
                                <button className="button" onClick={() => submit(event.id)} disabled={event?.status > 0}>
                                    {resources?.ButtonHome}
                                </button>
                            </div>
                            <div className="col-md-6 col-12">
                                {resources?.TicketsAvailable}: <span className="fw-bold">{event?.contigent}</span>
                            </div>
                        </div>
                    </div>
                </div>
            ))
        );
    };

    return (
        <div className="page-container pt-0">
            <Header />
            {!loading ? <div className="container wrapper">
                <div>{mapEvents()}</div>
            </div> : <Loading
                alignment="center"
                color="#f6b326"
                bgColor="#fff"
                position="fixed"
                top="50%"
                left="50%"
            />}
            <Footer />
        </div>
    );
};

export default Step05_Events;
