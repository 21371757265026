import React, { useEffect, useState } from "react";
import Header from "./components/Header";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

import "../css/style.css";
import Footer from "./components/Footer";

const Step20_Address = () => {
  const [token] = useState(sessionStorage.getItem("token"));
  const [orderId] = useState(sessionStorage.getItem("orderId"));
  const [errors, setErrors] = useState("");
  const [buyerInfo, setBuyerInfo] = useState({
    name: "",
    firstname: "",
    telefon: "",
    email: "",
  });

  const [cancellation, setCancellation] = useState(false);

  // react hook for navigation
  let navigate = useNavigate();

  // base url
  let baseUrl = process.env.REACT_APP_BASEURL_API;

  // fetching resources
  const [resources, setResources] = useState({});

  const { language } = useParams();

  let languageId = 0;

  if (language === "de" || language === "DE") {
    languageId = 1;
  } else if (language === "en" || language === "EN") {
    languageId = 3;
  } else {
    languageId = 1;
  }

  useEffect(() => {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  }, []);

  useEffect(() => {
    requestResources();
  }, [language]);

  const requestResources = async () => {
    await axios
      .get(`form/resources/${language}`)
      .then((res) => {
        setResources(res.data.translation);
      })
      .catch((error) => console.error(error.response.data));
  };

  const addAddressToBasket = async () => {
    let addressBody = {
      firstname: buyerInfo.firstname,
      name: buyerInfo.name,
      email: buyerInfo.email,
      telefon: buyerInfo.telefon,
      countryId: 176,
    };

    await axios
      .put(`${baseUrl}/Order/${orderId}/Address`, addressBody)
      .then(() => {
        changePaymentTypeToFree();
      })
      .catch((error) => {
        setErrors(error.response.data);
      });
  };

  const changePaymentTypeToFree = () => {
    axios
      .put(`${baseUrl}/ShopBasket/Order/${orderId}/PaymentType/5`)
      .then(() => {
        confirmOrder();
      });
  };

  // confirm the order
  const confirmOrder = () => {
    axios
      .put(`${baseUrl}/ShopBasket/Order/${orderId}/Confirm`)
      .then(() => {
        navigate(`/${language}/confirmation`);
      })
      .catch((error) => {
        console.error(error.response.data);
      });
  };

  const onInputChange = (e) => {
    setBuyerInfo({ ...buyerInfo, [e.target.name]: e.target.value });
  };

  const submit = () => {
    addAddressToBasket();
  };

  // validating input fields
  let validation =
    /^([a-z- A-Zöéàäèü - ]{1,70})$/.test(buyerInfo.firstname) &&
    /^([a-z- A-Zöéàäèü - ]{1,70})$/.test(buyerInfo.name) &&
    /^\S+@\S+\.\S+$/.test(buyerInfo.email) && cancellation;

  return (
    <div className="page-container pt-0">
      <Header />
      <div className="container wrapper">
        <p className="mb-4 fs-5 fw-bold">{resources?.TitleAddress}</p>
        <div className="mb-3 row">
          <label className="col-sm-2 col-form-label">{resources?.Firstname}*:</label>
          <div className="col-sm-10">
            <input
              className="form-control"
              name="firstname"
              id="inputFirstname"
              value={buyerInfo.firstname}
              onChange={(e) => onInputChange(e)}
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label className="col-sm-2 col-form-label">{resources?.Name}*:</label>
          <div className="col-sm-10">
            <input
              className="form-control"
              name="name"
              id="inputLastname"
              value={buyerInfo.name}
              onChange={(e) => onInputChange(e)}
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label className="col-sm-2 col-form-label">{resources?.Telefon}:</label>
          <div className="col-sm-10">
            <input
              className="form-control"
              name="telefon"
              id="inputTelefon"
              value={buyerInfo.telefon}
              onChange={(e) => onInputChange(e)}
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label className="col-sm-2 col-form-label">{resources?.Email}*:</label>
          <div className="col-sm-10">
            <input
              className="form-control"
              name="email"
              id="inputEmail"
              value={buyerInfo.email}
              onChange={(e) => onInputChange(e)}
            />
          </div>
        </div>
        <div className="form-check mb-3">
          <input
            className="form-check-input"
            type="checkbox"
            id="flexCheckDefault"
            checked={cancellation}
            onClick={(e) => setCancellation(e.target.checked)}
          />
          <label className="form-check-label ms-2" htmlFor="flexCheckDefault">
            {resources?.CancellationBox}
          </label>
        </div>
        <div className="mb-3 row">
          <div className="col-sm-12">
            <p>{resources?.FillAllFields}</p>
          </div>
        </div>
        <div className="row mt-4">
          <div className="text-start mb-3 col-md-6 col-12">
            <button className="button" onClick={() => navigate(-1)}>{resources?.ButtonAddressBack}</button>
          </div>
          <div className="text-end mb-5 col-6">
            <button className="button" onClick={submit} disabled={!validation}>
              {resources?.ButtonAddressDone}
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Step20_Address;
