import React, { useEffect, useState } from "react";
import Header from "./components/Header";
import { useParams } from "react-router-dom";
import axios from "axios";
import parse from "html-react-parser";

import "../css/style.css";
import Footer from "./components/Footer";

const Step30_Confirmation = () => {
  const [token] = useState(sessionStorage.getItem("token"));
  const [orderId] = useState(sessionStorage.getItem("orderId"));
  const [organizerId, setOrganizerId] = useState();
  const [orderInfo, setOrderInfo] = useState({});

  // base url
  let baseUrl = process.env.REACT_APP_BASEURL_API;

  // fetching resources
  const [resources, setResources] = useState({});

  const { language } = useParams();

  let languageId = 0;

  if (language === "de" || language === "DE") {
    languageId = 1;
  } else if (language === "en" || language === "EN") {
    languageId = 3;
  } else {
    languageId = 1;
  }

  useEffect(() => {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  }, []);

  useEffect(() => {
    requestFormSettings();
    requestResources();
  }, []);

  const requestFormSettings = () => {
    axios.get(`form/formsettings`).then((res) => {
      setOrganizerId(res.data.organizerId);
      requestOrderInformation();
    });
  };

  const requestResources = async () => {
    await axios
      .get(`form/resources/${language}`)
      .then((res) => {
        setResources(res.data.translation);
      })
      .catch((error) => console.error(error.response.data));
  };

  const requestOrderInformation = () => {
    axios.get(`${baseUrl}/Order/${orderId}`).then((res) => {
      setOrderInfo(res.data);
    });
  }

  const getPdfTicketsOfOrder = async () => {
    await axios
      .get(
        `${baseUrl}/Order/${orderId}/TicketsPdf?organizerId=${organizerId}`,
        {
          responseType: "blob",
        }
      )
      .then((res) => {
        const data = window.URL.createObjectURL(res.data);
        var link = document.createElement("a");
        link.href = data;
        link.download = "Tickets.pdf";
        link.click();
      })
      .catch((error) => console.error(error.response.data));
  };

  return (
    <div className="page-container pt-0">
      <Header />
      <div className="container wrapper mb-5">
        <div className="text-center mb-5">
          <p className="fs-6">
            {parse(resources.DescriptionConfirmation ? resources?.DescriptionConfirmation : "")}
          </p>
        </div>
        <p className="fs-6 fw-bold">{resources?.DescriptionInvoice} {orderInfo.invoiceNumber}</p>
        <p>
          {resources?.DescriptionInvoice1}
        </p>
        <button className="button mt-3" onClick={() => getPdfTicketsOfOrder()}>
          {resources?.DownloadTicket}
        </button>
      </div>
      <Footer />
    </div>
  );
};

export default Step30_Confirmation;
