import React from "react";

import "../../css/style.css";
import banner from "../../images/banner.jpg";
import header from "../../images/header.jpg";

const Header = (props) => {
  return (
    <div>
      <div className="header-img mb-3">
        <div className="container text-center header-img">
          <img
            src={header}
            className="text-center img-fluid"
            alt="sika banner"
          ></img>
        </div>
      </div>
      <div className="container col-12">
        <p className="text-center fw-bold fs-3">
          {props.title}
        </p>
      </div>
    </div>

  );
};

export default Header;
